  .collection-item{
    background-color: $color1;
    color: $color0;
    margin: 15px auto;
    
    width: 200px;
    border-radius: 15px;
    
    overflow: hidden;
  }

  .collection-item:hover{
        scale: 1.05;
        cursor: pointer;
    }
  .collection-img-container{
    align-items: center;
    text-align: center;

  }

  .collection-img{
      width: 100%;
  }
  

.item-name{
    font-weight: bold;
    margin: 5px;
    text-align: center;
}
.item-name-big{        
    font-weight: bold;
    margin: 5px;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
}
    
.collection-item-footer{
    text-align: center;
    display: block !important;
    border-color: $color3;
}

.attribute-box{    
    display:inline-block;
    text-align: center;
    padding: 5px;
    margin: 5px 5px;
    border-radius: 5px;
    padding: 2px 10px;
    min-width: 120px;
    background-color:rgb($color0,0.3);
    border: solid 1px $color3;
}

.attribute-type{
  font-weight: bold;
  color:$color3;
}


@media (max-width: 768px) { 
  .collection-item{
    width: 100px;
  } 
}

@media (max-width: 400px) { 
  .collection-item{
    width: 80px;
  } 
}
