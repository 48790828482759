.navbar-small{
    width: 100%;
    height: $navBarHeight;
    position: fixed;
    top:0px;
    font-size: 20px;
    .navbar-dropdown-icon{
        font-size: 4rem;
        color: $color0;
        margin: 10px;
        &:hover{
            cursor: pointer;
        }
    }
    .navbar-sided{
        position: fixed;
        height: 100vh;
        z-index: 1 !important;
        color: $color0;
        background: $color1;
        width:200px;
        .navbar-element {
            margin: 15px;
        }
    }
}

.navbar-big{
    width: 100%;
    height: $navBarHeight;
    position: fixed;
    top:0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    gap: 40px; 
    .navbar-element { 
        display: table-cell; 
        position: relative; 
        padding: 15px 0;
    } 
    
}

.navbar-element { 
    position: relative; 
    padding: 15px 0;
} 
a {
    padding: 15px 20px;
}
a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: $color3;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
a:hover:after {
    width: 100%; 
    left: 0; 
}

.navbar-visible{
    background-color: rgba($color0, 0.9);
    transition: background-color 1s ease;    
    box-shadow: 0 1px 10px rgba($color0, 0.3);
}

.il-logo{
    cursor: pointer;
    position:fixed;
    top:15px;
    left:15px;
    z-index: 1;
    img{
        height:$navBarHeight - 15px;
    }
    a{
        padding: 0;
    }
    a:after {    
        all: unset;
    }
}
.il-logo-small{
    left:unset;
    right:10px;
    img{
        height:$navBarHeight - 20px;
    }
}