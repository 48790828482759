$footer-height:80px;
.footer-container{
    margin-top: 100px;
    height: $footer-height !important;

    color: $color1;
    background-color: $color0;

    text-align: center;
    padding: 11px;
    font-size: 1rem;
}

$button-size:40px;
.button-backtotop,
.button-backtotop-footer{
    opacity: 0.4;
    cursor: pointer;

    width:$button-size;
    height:$button-size;

    color: $color0;
    background-color: $color3;
    border: $color3 solid 1px !important;
    border-radius: 50%;

    font-size: 2rem;
    &:hover{
        opacity: 0.999;
        scale: 1.1;
    }
    div{  
        margin-top: 2px;
        animation: float 2s infinite ease-in-out;
    }
}

.button-backtotop{
    position: fixed;
    bottom:25px;
    right:calc(50% - $button-size / 2);
}

.button-backtotop-footer{
    position: fixed;
    bottom:calc($footer-height + 10px);
    right:calc(50% - $button-size / 2);
}

.footer-socialmedia{
    a{
        font-size: 1.5rem;
    }
}

@media (max-width: 576px) {


}

@keyframes float {
    0%, 100% {
      transform: translateY(+1px);
    }
    50% {
      transform: translateY(-4px);
    }
  }