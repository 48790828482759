.landing-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(85vh - 15px);  /* Full viewport height */
}

.content {
  text-align: center;
  max-width: 100%;
}

.landing-image-container{
    margin-top: 40px;
    margin-bottom: 40px;
    img{
        height: 100%;
        width: 100%;
        max-height: 40vh;
        max-width: 40vh;
    }
}

.landing-projectdescription-container{
  font-size: 2rem;
  width: 80%;
  margin: 30px auto;
  font-style: italic;    
}

@media (max-width: 992px) {
    .landing-title-container{
        h1{        
            font-size: 7vw;
        }
        h2{
            font-size: 5vw;
        }
    }
    .landing-projectdescription-container{
        font-size: 2.5vw;
      }
  }

@media (max-width: 768px) {
    .landing-container{
        margin-bottom: 0;
    }
    .landing-title-container{
        h1{        
            font-size: 7vw;
        }
        h2{
            font-size: 5vw;
        }
    }
    .landing-projectdescription-container{
        font-size: 3.5vw;
      }
  }

  @media (max-width: 512px) {
    .landing-container{
        margin-bottom: 0;
    }
    .landing-title-container{
        h1{        
            font-size: 8vw;
        }
        h2{
            font-size: 6vw;
        }
    }
    .landing-projectdescription-container{
        font-size: 4vw;
      }
  }
/* 

@media (max-width: 768px) {
    .landing-container{        
        min-height: unset; 
    }
    .landing-image-container{
        img{
            height: 200px;
            width: 200px;
        }
    }
    .landing-projectdescription-container{
        font-size: 1.5rem;
        width: 100%;
        margin: 10px auto;
        padding: 10px;
    }
  } */