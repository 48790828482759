.projectteam-container{
    overflow: hidden;
    .teammember-information{
        display:  flex;
    }
    
    .teammember-description{
        margin: auto auto;
        font-size: 1.2rem;
        .small-divider{
            margin: 7px;
        }
    }
    .teammember-name{
        font-size: 2rem;
        font-family: $font-titles;
        color: $color3;
    }
    .teammember-img-container{
        margin: auto auto;
        img{
            width: 100%;
            max-height: 300px;
            max-width: 300px;
            border-radius: 15px;
        }
    }
}
