.collection-filters {
     border-radius: 15px;
}

.collection-filters-buttons{
    display: inline-flex;
}
#resetfilter-button{    
    margin-right: 15px;
}
.filter-area-traittype{    
    margin: 10px auto;
    padding: 5px;
    background-color: $color2;
    border-radius: 15px;
    &:hover{
            cursor: pointer;
        .filter-dd-arrow{
            scale:1.2;
            color:$color3;
        }
    }
}

.filter-area-title{
    font-size: 1.5rem;
    text-align: center;
    margin: 10px 0px;
}
.filter-dd-arrow{
    float: right;
    margin-right: 15px;
}
.radio-container{
    margin-top: 15px;
    .radio-container-select{
        margin: 1px 0px;
        height: 30px;
        align-content: center;
        text-align: center;
        cursor: pointer;
    }    
    :hover{
        color: $color0;                 
        background-color: $color3;
        border-radius: 5px;  
    }
    .selected-radio{
        color: $color0;     
        background-color: $color3;
        border-radius: 5px;
    }
}

@media (max-width: 768px) { 
    .filter-area-traittype{
        .small-divider{
            margin:0px auto
        }
        .radio-container{
            margin-top: 5px;
        }
        .radio-container-select{
            height: 25px;
        }  
    }
  }