.faq-container{
    min-height: 100vh;
    max-width: 1000px;
    
}

.faq-item-container{
    padding: 20px;
}
.faq-question{
    font-size: 1.8rem;
    color: $color3;
    margin-bottom: 15px ;
}
.faq-response {
    text-align: center;
    font-size: 1.3rem;
}

@media (max-width: 576px) {
    .faq-item-container{
        padding: 5px;
    }
    .faq-question{
        margin-bottom: 15px ;
    }
}