.projectroadmap-container{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.phase-container{
    display: flex;
    padding: 15px 20px;
    h3{
        margin-bottom: 20px;
    }
}

.phase-text-container{
    font-size: 1.5rem;
    width: 100%;
    p{
        width: 100%;
    }
    li{
        margin-top:15px;
        margin-left:15px;
    }
}
.phase-image-container{
    margin:auto 20px;
    img{
        height: 100px;
        border-radius: 15px;
    }
}

@media (max-width: 768px) {
    .phase-image-container{
        img{
            height: 70px;
        }
    }
  }