.nftutility-container{
    ul{        
        font-size: 1.2rem;
        list-style-type: circle;
    }

    .nftutility-description{
        display: flex;
    }
}

