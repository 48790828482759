@import "https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap";
@import "https://fonts.googleapis.com/css2?family=Darumadrop+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Concert+One&display=swap";


$color0: #232741;/* Background color */
/* $color0: #466475;  */
$color1: #fffffff3; /* Writing color */
$color2:#466475; 
$color3:#E2CC6D ; /* Highlight color */
$color4: #8bdf71; /* Highlight color */

/* $color1: #fffffff3;
$color0: #222531; 
$color2:#b578ff; 
$color3:#28f195 ; 
$color4: #87befd; 
$color5: #02ac51; */

$test:#ffffff0a;

$chartColor1: #00b7b3;
$chartColor2: #acacac;

$minusColor: rgb(233, 85, 85);
$plusColor: rgb(156, 206, 122);

$verylight-black : rgba(0,0,0,0.10);

$font-classic:"Concert One", sans-serif;
$font-titles:"Darumadrop One", sans-serif;

$navBarHeight:80px;

:export {
    chartColor1: $chartColor1;
    chartColor2: $chartColor2;
    color1: $color1;
    color2: $color2;
    color3: $color3;
    color4: $color4;
  }

  .font-color0{
    color: $color0 !important
  }
  .font-color1{
    color: $color1 !important
  }
  .font-color2{
    color: $color2 !important;
  }
  .font-color3{
    color: $color3 !important;
  }  
  .font-color4{
    color: $color4 !important;
  }
  .font-minusColor{
    color: $minusColor !important;
  }
  .font-plusColor{
    color: $plusColor !important;
  }

/* NATIVE TAGS */
html{  
  font-size: 12px;
  scroll-behavior:smooth;
}

body{
    background-color: $color0;
    color: $color1;
    font-family:$font-classic;
    margin-top: $navBarHeight; 
    z-index: 10;
}

section{
  margin: auto auto;
  margin-top:130px;
}

a{
 all:unset;
 cursor: pointer;
}
.page-component{
  margin-top: 200px;
}

h1,h2,h3{
    font-family: $font-titles;
    color: $color1;
    text-align: center;
}
h1{
  color: $color3;
  font-size:7rem;
}
h2{  
  font-size: 4rem;
}


/* GENERIC CLASS */
.comment{
  font-size: 1rem;
  font-style: italic;
  color: rgb(204, 204, 204);
}
.centered-text{
    margin: 0px 0px !important;
    text-align: center;
}
.centered-div{ 
  margin: auto auto;
  text-align: center;
  justify-content: center;
}
.align-left{
  text-align: left;
}
.highlight-box{
    width: 95%;
    max-width: 700px;
    background-color: $color3;
    border-radius: 15px;
    padding: 15px;
    margin: auto auto;
}

.flex-container{   
    display: flex;
    justify-content: center;
    div{
        margin:10px auto;
    }
}

.blurred{
  filter: blur(5px);
}

.round-border{
  border-radius: 15px;
}

.small-shadow{  
  box-shadow: 0px 0px 5px rgba($color3, 0.3);
}
.mid-shadow{  
  box-shadow: 0px 0px 10px rgba($color3, 0.8);
}

.italic{
  font-style: italic;
}

/* MODAL */
.modal-content{
  background-color: $color2;
}
.modal-header{
  border-bottom: none;
  padding-bottom: 0px;
  .btn-close{
    background-color: $color3;
  }
}

/* BUTTONS */
.squared-tinytext-button,
.squared-shorttext-button,
.squared-midtext-button,
.squared-fullwidth-button,
.squared-flex-button  {
  color:$color0;
  background-color: $color3;
  text-align: center;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
  &:hover{
    color:$color0;
    background-color: $color4;
    cursor: pointer;
  }
}

.squared-tinytext-button {
  width: 70px;
}
.squared-shorttext-button {
  width: 150px;
}
.squared-midtext-button  {
  width: 250px;
}
.squared-fullwidth-button  {
  width: 100%;
}
.squared-flex-button  {
  display: inline-block;
  padding-right: 2rem;
  padding-left: 2rem;
}

/* TABLE */
.table-buttons-container{  
  max-width: 900px;
  width: 100%;
  margin: auto auto;
}
.table-container {
  text-align: center;
  color: $color1 !important;
  width: 100%;
  max-width: 900px;
  margin: auto auto;
  margin-bottom: 30px;

  background-color: $color3;
  border-radius: 15px;
  padding: 15px;  
  li {
      list-style: none;
    }
  ul {
      padding: 0;
  }
}
.table-line-above-table {
  border-bottom: #00b7b3 solid 1px !important;
  font-weight: bold;
  font-style: italic;
}
.table-header{
  display: grid;
  height: 44px;
  align-items: center;
  width: 100%;
  transition: 0;
  border-bottom: #00b7b3 solid 1px;
  font-size: 1rem;
}
.table-line, .table-line-above-table{
  display: grid;
  text-align: center;
  align-items: center;
  height: 50px;
  border-bottom: $color2 solid 1px ;        
  font-size:1rem;

  .tablecol-coinname {
    height: 100%;
    align-items: center;
    text-align: left;    
    span {
    margin-left: 2px;
    letter-spacing: 1px;
    }
  }     
  .tableline-second-row{
    white-space: nowrap;
    text-align: left;
    margin-left: 50px;
    font-style: italic;
    color: $color3;
  }
}

.two-column-table{
  .table-header,
  .table-line {
      $numberOfColumns:2;
      grid-template-columns: repeat($numberOfColumns, calc(100%/$numberOfColumns));
      text-align: left;
      
  }
}

.link-style{
  color: inherit; /* Use the same color as the surrounding text */
  text-decoration: none; /* Remove underline */
  cursor: pointer;
}


/* MISC */
.bold{
  font-weight: bold;
}
.underlined{
  text-decoration: underline;
  text-decoration-color: $color3;
  text-decoration-thickness: 3px;
}
.small-divider{
  height:1px;
  background-color: $color3;
  margin:20px auto 20px auto;
  border-radius:50%;
}

.big-divider{  
  height:5px;
  background-color: $color3;
  margin:20px auto 20px auto;
  width: 150px;
  border-radius: 0% 100% 0% 100%;
}

.showmore-text{
  color:$color3;
  text-decoration: underline;
  cursor: pointer;
}
/* PROJECT */
#tsparticles{
  position: fixed;
  width: 100%;
  z-index: -1;
}

.section-title{
  color: $color3;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 50px;
}

.rounded-border-box{
  min-height: 200px;
  background-color: rgba($color3, 0.2);
  border-radius: 15px;
  padding: 15px;
  margin: 10px 10px;
  text-align: center;
  h4{
      font-size: 2rem;
      font-family: $font-titles;
      color: $color3;
  }
    &:hover{
      scale:1.05
    }
}
/* RESPONSIVE */
@media (max-width: 576px) {
  html {
    font-size: 10px;
  }
  body{
    margin-top: $navBarHeight + 15px !important;
  }
  .page-component{
    margin-top: 100px;
  }
  .table-container  {
    font-size: 0.7rem;
  }
  .table-line, .table-line-above-table{
    height: 40px;
    .tableline-second-row{
      margin-left: 15px;
    }
  }

  .rounded-border-box{
    min-height: 100px;
  }
}

@media (max-width: 768px) {
  body{
    margin-top: $navBarHeight + 15px !important;
  }
  h1{
    font-size: 5rem;
  }
  h2{
    font-size: 3rem;
  }  
  section{
    margin: 40px 20px;
  }
  .page-component{
    margin-top: 100px;
  }
}